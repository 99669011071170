<template>
  <div class="create-rules" ref="createRules">
    <div class="create-form h-100">
      <div class="form-content content">
        <h3 class="mb-3 mr-1 bread-content" :class="{'hidden': isMission}">
          <span>{{ stepNumber }}. {{ texts.createPage.rules.title }}</span>
          <IconWithTooltip class="mt-2 ml-2 font-xl" :text="getDescription()"/>
        </h3>
        <div class="strategies" v-if="context === 'achievement'">
          <div class="points-strategy " :class="{'flex-column': isMission}">
            <div
              class="score-points-statement input-wrapper"
              :class="{'w-100 font-mini': isMission, 'w-50 font-default': !isMission}"
            >
              {{ texts.scorePoints.statement }}
            </div>
            <div class="d-flex align-items-center">
              <div class="input-wrapper">
                <multiselect
                  v-model="operator"
                  :options="operatorOptions"
                  :hide-selected="true"
                  :selectLabel="'operator'"
                  :placeholder="'operator'"
                  class="zq--multiselect operator-select"
                  track-by="key"
                  label="name"
                />
              </div>
              <div class="input-wrapper">
                <input
                  class="points-input"
                  v-model="pointsValue"
                  :name="'pointsValue'"
                  type="number"
                  min="0"
                />
              </div>
              <div
                class="connective input-wrapper"
                v-if="operator.key === 'Between' || operator.key === 'AverageBetween'"
              >
                {{ texts.scorePoints.and }}
              </div>
              <div
                class="input-wrapper"
                v-if="operator.key === 'Between' || operator.key === 'AverageBetween'"
              >
                <input
                  class="points-input"
                  v-model="pointsValueUpper"
                  :name="'pointsValueUpper'"
                  type="number"
                  min="0"
                />
              </div>
            </div>
          </div>

        </div>
        <!-- Actions  -->
        <div class="rules-header-actions">
          <div class="rules-header-actions--left">
            <!--    Rules Dropdown      -->
            <div class="rules-drop-select" v-if="isRulesDropDown">
              <multiselect
                :disabled="isFieldDisabled"
                v-model="rulesType"
                :options="rulesOption"
                label="title"
                track-by="title"
                :hide-selected="true"
                :placeholder="texts.createPage.rules.typesPlaceholder"
                :selectLabel="selectLabel"
                class="custom-multi-select zq--multiselect zq--select--no-press-btn"
              >
                <template slot="option" slot-scope="props">
                  <i :class="props.option.img" class="mr-1" :style="{color: props.option.color}"/>
                  <span>{{ props.option.title }}</span>
                </template>
              </multiselect>
            </div>
          </div>

          <div class="rule-actions mb-2">
            <ClCustomButton class="mr-2 mb-1" icon @click="toggleJsonView" v-if="!isMission">
              <template #right-icon>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g fill-rule="evenodd">
                    <g fill-rule="nonzero">
                      <g>
                        <g>
                          <g>
                            <path
                              d="M14.371 6.072c.477.19.726.705.595 1.189l-.038.11-4 10c-.205.513-.787.763-1.3.557-.476-.19-.725-.705-.594-1.189l.038-.11 4-10c.205-.513.787-.763 1.3-.557zm4.325.21l.085.093 4 5c.265.332.29.791.072 1.147l-.072.103-4 5c-.345.431-.974.5-1.406.156-.398-.319-.488-.88-.228-1.303l.072-.103L20.72 12l-3.5-4.375c-.318-.398-.283-.965.063-1.321l.093-.085c.398-.318.965-.283 1.321.063zM6.625 6.22c.398.319.488.88.228 1.303l-.072.103L3.28 12l3.5 4.375c.32.398.284.965-.062 1.321l-.093.085c-.398.318-.965.283-1.321-.063l-.085-.093-4-5c-.265-.332-.29-.791-.072-1.147l.072-.103 4-5c.345-.431.974-.5 1.406-.156z"
                              transform="translate(-693 -352) translate(136 344) translate(549) translate(8 8)"/>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </template>
            </ClCustomButton>

            <!--     RESET     -->
            <ClCustomButton
              v-if="!isTemplatesTable"
              @click="handleResetRules"
              :disabled="isFieldDisabled"
              :text="$t('buttons.clear')"
              class="mr-2 mb-1"
            />
            <ClCustomButton
              v-else
              @click="closeTemplatesTable"
              :disabled="isFieldDisabled"
              :text="$t('buttons.goBack')"
              class="mr-2 mb-1"
            />
            <!--    Save as Template      -->
            <ClCustomButton
              :disabled="isFieldDisabled"
              :text="$t('buttons.SaveAsTemplate')"
              class="mr-2 mb-1"
              @click="openSaveAsTemplateModal"
            />
            <!--    Load Template      -->
            <ClCustomButton
              :disabled="isFieldDisabled"
              :text="$t('buttons.LoadTemplate')"
              class="mr-2"
              @click="openTemplatesTable"
            />
            <!--    Expand       -->
            <ClCustomButton class="mr-2 rules-expand-btn" icon @click="expandRules" v-if="!isMission">
              <template #right-icon>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-if="!expanded">
                  <g fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g fill-rule="nonzero" stroke-width="2">
                      <g>
                        <g>
                          <g>
                            <path d="M4 20L4 16M16 4L20 4M20 8L20 4M4 20L10 14M4 20L8 20M14 10L20 4"
                                  transform="translate(-1192 -352) translate(136 344) translate(1048) translate(8 8)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-else>
                  <g fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g fill-rule="nonzero" stroke-width="2">
                      <g>
                        <g>
                          <g>
                            <path d="M10.5 13.5L3 21M10.5 13.5V19.1M10.5 13.5H4.9" stroke-width="2"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.5 10.5L21 3M13.5 10.5V4.84315M13.5 10.5H19.1568" stroke-width="2"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </template>
            </ClCustomButton>
          </div>
        </div>
        <TemplatesTable
          v-if="isTemplatesTable"
          @getFromTemplate="getFromTemplate"
          :context="context"
        />
        <CCard class="zq--wizard-card" v-if="isRules && !isTemplatesTable">
          <CCardBody class="d-flex">
            <div class="field-paper" v-if="isFieldDisabled"></div>
            <RulesEngine
              @change-data="onChange"
              :query="query"
              :routines="routines"
              :rules="rules"
              ref="rulesEngine"
              :key="rulesEngineKey"
              :isJsonView="isJsonView"
            />
          </CCardBody>
        </CCard>
        <PreviewSpiner v-else-if="!isTemplatesTable"/>
        <Modal
          :modalShow="isSaveAsTemplateModal"
          :title="'Save As Template'"
          :messageGeneral="'Enter Template Name'"
          :successBtnLabel="'Save'"
          @doFunction="saveAsTemplate"
          @toggle-modal="closeModal"
        >
          <template #body>
            <CInput
              v-model="templateName"
              :placeholder="'Template Name'"
              :isrequired="true"
              add-input-classes="col-sm-12"
              name="templateName"
              @input="trimTemplateName"
            />
          </template>
        </Modal>
        <Modal
          :modalShow="clearModal"
          :messageGeneral="`This will clear all rules  for this ${context}`"
          :title="'Clear Rules'"
          @doFunction="resetRules"
          v-on:toggle-modal="closeClearModal"
        />
        <RulesTemplateModal
          v-if="isMission"
          :isTemplatesTableModal="isTemplatesTableModal"
          @getFromTemplate="getFromTemplate"
          :context="context"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import ClCustomButton from '@/shared/components/formComponents/ClCustomButton';
import TemplatesTable from '@/shared/components/supportModels/rules/TemplatesTable';
import PreviewSpiner from '@/shared/UI/Spiner';
import Modal from '@/shared/UI/Modal';
import { isAchFieldDisabled, isCompFieldDisabled } from '@/utils/validationUtils';
import { formConfig } from '@/config';
import { achievements } from '@/config/descriptions/achievements';
import { achievementQuery } from '@/helpers/rules/achievementQuery';
import { competitionQuery } from '@/helpers/rules/competitionQuery';
import { contestQuery } from '@/helpers/rules/contestQuery.json';
import { defaultRoutines } from '@/helpers/rules/routines';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import conditionalOperator from '@/generated/ziqni/fields/ConditionalOperatorZq';
import RulesTemplateModal from '@/components/mission/RulesTemplateModal.vue';

export default {
  name: 'CreateRules',
  components: {
    RulesTemplateModal,
    IconWithTooltip,
    ClCustomButton,
    PreviewSpiner,
    Modal,
    TemplatesTable,
  },
  props: {
    status: {
      type: String,
      default: 'Ready'
    },
    isRulesDropDown: {
      type: Boolean,
      default: false
    },
    pageType: {
      type: String,
      default: 'Ach'
    },
    stepNumber: {
      type: Number,
      default: 6
    },
    context: {
      type: String,
      default: ''
    },
    rulesQueryData: {
      type: Object,
      default() {
        return {};
      },
    },
    strategiesData: {
      type: Object,
      default() {
        return {};
      }
    },
    model: { type: String, default: '' },
    isMission: { type: Boolean, default: false }
  },
  data() {
    return {
      query: cloneDeep(achievementQuery),
      startQuery: {},
      selectLabel: formConfig.selectLabel,
      rulesType: '',
      rulesOption: [
        { title: 'Event points', img: 'fa fa-exclamation-circle', color: '#eaa642' },
        { title: 'Start the contests', img: 'fa fa-check-circle', color: '#97c664' },
        { title: 'Finish the contests', img: 'fa fa-check-circle', color: '#97c664' },
        { title: 'Finalise the contests', img: 'fa fa-check-circle', color: '#97c664' },
        { title: 'Custom points', img: 'fa fa-check-circle', color: '#348ee3' },
        { title: 'Entrant progression', img: 'fa fa-check-circle', color: '#348ee3' },
        { title: 'Cancel the contests', img: 'fa fa-check-circle', color: '#348ee3' },
      ],
      operatorOptions: [],
      operator: '',
      pointsValueUpper: null,
      pointsValue: 0,
      descriptions: {
        rules: achievements.list.create.rules.rules,
      },
      texts: {
        ...achievementsTexts
      },
      routines: defaultRoutines,
      rules: {},
      startRules: {},
      rulesEngineKey: 0,
      isJsonView: false,
      isRules: false,
      expanded: false,
      clearModal: false,
      contestQuery: contestQuery,
      isSaveAsTemplateModal: false,
      templateName: '',
      templateData: {},
      isTemplatesTable: false,
      isTemplatesTableModal: false,
    };
  },
  computed: {
    isFieldDisabled() {
      if (this.pageType === 'Ach') {
        return isAchFieldDisabled(this.status);
      }
      return isCompFieldDisabled(this.status);
    },
    rulesDescriptions() {
      const context = require.context('@/config/descriptions/', true, /\.json$/);
      const descriptions = {};

      context.keys().forEach((key) => {
        const modelName = key.replace(/\.\/|\.json/g, '');
        descriptions[modelName] = context(key);
      });

      return descriptions;
    }
  },
  watch: {
    operator(value) {
      if (value) {
        this.operator = value;

        this.$emit('updateStrategiesOperatorData', value);

        if ((value.key === 'Between' || value.key === 'AverageBetween') && !this.pointsValueUpper) {
          this.pointsValueUpper = +this.pointsValue + 10;
        }

        if ((value.key !== 'Between' || value.key !== 'AverageBetween') && this.pointsValueUpper) {
          this.$emit('updateStrategiesPointsValueUpperData', null);
        }
      }
    },
    pointsValue(value) {
      if (value) {
        this.pointsValue = value;
        this.$emit('updateStrategiesPointsValueData', value);
      }
    },
    pointsValueUpper(value) {
      if (value) {
        this.pointsValueUpper = value;
        this.$emit('updateStrategiesPointsValueUpperData', value);
      }
    }
  },
  created() {
    this.operatorOptions = this.transformFromCamelCaseArray(conditionalOperator.allowableValuesKeys);
    this.initialize();
  },
  methods: {
    ...mapActions('rules', ['handleGetRuleSchemas']),
    ...mapActions('files', ['handleSaveTemplates']),
    ...mapActions('fileRepositories', ['handleGetFileRepositories', 'handleGetFileRepositoriesByQuery']),
    getDescription() {
      if (this.rulesDescriptions[this.model]) {
        const mainModel = this.rulesDescriptions[this.model];

        return mainModel[this.model]['create']['rules']['rules'];
      } else {
        return this.descriptions.rules;
      }
    },
    async initialize() {
      if (this.context === 'competition') {
        this.query = cloneDeep(competitionQuery);
        this.startQuery = cloneDeep(competitionQuery);
      }
      if (this.context === 'contest') {
        this.query = cloneDeep(this.contestQuery);
        this.startQuery = cloneDeep(this.contestQuery);
      }
      if (this.context === 'achievement') {
        this.query = cloneDeep(achievementQuery);
        this.startQuery = cloneDeep(achievementQuery);

        if (this.strategiesData.operator) this.operator = this.operatorOptions.find(obj => obj['key'] === this.strategiesData.operator);
        this.pointsValueUpper = this.strategiesData.pointsValueUpper;
        this.pointsValue = this.strategiesData.pointsValue;
      }

      if (Object.keys(this.rulesQueryData).length) {
        for (const [key, value] of Object.entries(this.rulesQueryData)) {
          if (this.rulesQueryData[key]) {
            this.query[key] = value;
          }
        }
        this.templateData = cloneDeep(this.query);
      }

      await this.handleGetRuleSchemas({ context: this.context })
        .then((items) => {
          const schemas = cloneDeep(items[0]);
          const pointsIdx = schemas.actions.findIndex(item => item.action === 'contest.points');

          if (pointsIdx !== -1) {
            const points = schemas.actions[pointsIdx];
            schemas.actions.splice(pointsIdx, 1);
            schemas.actions.unshift(points);
          }

          this.rules = cloneDeep(schemas);
          this.startRules = cloneDeep(schemas);

          const routines = cloneDeep(schemas.routines);

          if (routines && routines.length) {
            this.routines = routines.map(routine => {
              if (routine.arguments[0].macros && routine.arguments[0].macros.length) {
                const macros = [];
                for (let i = 0; i < routine.arguments[0].macros.length; i++) {
                  macros.push({ scope: '$variable', value: routine.arguments[0].macros[i] });
                }
                routine.arguments[0].macros = macros;
              }

              if (routine.parameter.macros && routine.parameter.macros.length) {
                const macros = [];
                for (let i = 0; i < routine.parameter.macros.length; i++) {
                  macros.push({ scope: '$variable', value: routine.parameter.macros[i] });
                }
                routine.parameter.macros = macros;
              }

              return routine;
            });
          }

          this.isRules = true;
        });
    },
    toggleJsonView() {
      this.isJsonView = !this.isJsonView;
    },
    handleResetRules() {
      this.clearModal = true;
    },
    resetRules() {
      const tabs = this.$refs.rulesEngine ? this.$refs.rulesEngine.$el.querySelectorAll('.tabs-component-panel') : [];
      if (tabs.length) {
        tabs.forEach(tab => {
          const topLevelGroup = tab.querySelector('.rew-group.rew-group--depth-0');
          const clearButton = topLevelGroup.querySelector('.rew-icon-button.rew-icon-button--danger');
          if (clearButton) {
            clearButton.click();
          }
        });
      }
      this.clearModal = false;
    },
    expandRules() {
      if (this.$refs.createRules.classList.contains('create-rules--expand')) {
        this.$refs.createRules.classList.remove('create-rules--expand');
      } else {
        this.$refs.createRules.classList.add('create-rules--expand');
      }
      this.expanded = !this.expanded;
    },
    onChange(data) {
      if (Object.keys(data).length) {
        for (const [key, value] of Object.entries(data)) {
          if (data) {
            this.templateData[key] = value;
            this.query[key] = value;
          }
        }
        this.$emit('updateRulesData', data);
      }
    },
    async saveAsTemplate() {
      if (!this.templateName) {
        console.error('templateName error');
      } else if (!Object.keys(this.templateData).length) {
        console.error('templateData empty');
      } else {
        const repository = await this.handleGetFileRepositoriesByQuery({
          queryRequest: {
            must: [{
              queryField: 'name',
              queryValues: ['system-resources'],
            }]
          }
        });

        const templateString = JSON.stringify(this.templateData);

        const folderName = this.getTemplateFolderName();

        const payload = {
          tagsArray: 'rules',
          parentFolderPath: folderName,
          repositoryId: repository[0].id,
          templateToSave: templateString,
          templateName: this.templateName,
        };

        await this.handleSaveTemplates(payload);
      }
      this.isSaveAsTemplateModal = false;
    },
    getFromTemplate(template) {
      this.isTemplatesTable = false;
      this.isTemplatesTableModal = false;
      if (Object.keys(template).length) {
        for (const [key, value] of Object.entries(template)) {
          if (template) {
            this.query[key] = value;
          }
        }
        this.templateData = cloneDeep(template);

        this.$emit('updateDataFromTemplate', template);
        if (this.isMission) this.rulesEngineKey++
      }
    },
    trimTemplateName(title) {
      this.templateName = title.replaceAll(' ', '');
    },
    getTemplateFolderName() {
      let folderName = '';

      switch (this.context) {
        case 'competition':
          folderName = '/rules/competitions';
          break;
        case 'contest':
          folderName = '/rules/contests';
          break;
        case 'achievement':
          folderName = '/rules/achievements';
          break;
      }

      return folderName;
    },
    openSaveAsTemplateModal() {
      this.isSaveAsTemplateModal = true;
    },
    openTemplatesTable() {
      if (this.isMission) {
        this.isTemplatesTableModal = true;
      } else {
        this.isTemplatesTable = true;
      }
    },
    closeTemplatesTable() {
      this.isTemplatesTable = false;
    },
    closeClearModal() {
      this.clearModal = false;
    },
    closeModal() {
      this.isSaveAsTemplateModal = false;
      this.isTemplatesTableModal = false
    },
    transformFromCamelCaseArray(arr) {
      return arr.map(word => {
        const name = word.replace(/([A-Z])/g, ' $1').trim();
        return { key: word, name };
      });
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";

.font-default {
  font-size: 1.53125rem;
}

.font-mini {
  font-size: 12px;
}

.hidden {
  display: none !important;
}

.strategies {
  display: flex;
  flex-direction: column;

  .points-strategy {
    display: flex;
    padding-bottom: 10px;

    .score-points-statement {
      padding-right: 10px;
      font-weight: 500;
    }

    .connective {
      text-transform: uppercase;
      padding-left: 5px;
    }

    .input-wrapper {
      display: flex;
      align-items: center;

      .input-title {
        padding-left: 7px;
        padding-bottom: 5px;
      }
    }

    .operator-select {
      width: 200px;
    }

    .points-input {
      width: 150px;
      border: none;
      margin-left: 5px;

      &:focus {
        outline: none;
      }
    }
  }
}

.create-rules {
  &--expand {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    min-height: 100%;
    background: var(--zq-content-bgc);
  }

  .rules-wrap {
    padding-bottom: 60px;
  }

  .rules-header-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    &--left {
      display: flex;
      @include media-breakpoint-down('635px') {
        margin-bottom: 1rem;
      }
      @include media-breakpoint-down('576px') {
        .rules-drop-select {
          width: 200px;
        }
      }
      @include media-breakpoint-down('300px') {
        flex-wrap: wrap;
        justify-content: flex-end;
        .rules-drop-select {
          width: 100%;
          margin-bottom: .5rem;
        }
      }
    }

    .rule-set {
      flex: 1
    }

    .rule-actions {
      flex: 3;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      //margin-bottom: 1rem;
    }
  }

  .field-paper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    background: grey;
    opacity: 0.1;
  }

  .rules-drop-select {
    width: 220px;
    margin-right: 1rem;
  }

  .zq--wizard-card {
    margin: 0;

    .card-body {
      border-top: none;
      overflow-x: auto;
      overflow-y: auto;

      &::-webkit-scrollbar {
        height: 3px;
        width: 3px;
        background-color: #f0f0f0;
      }
    }
  }

  .tabs-component-tab {
    margin-bottom: -1px;
  }

  .rew-group__rule .rew-expression-input-wrapp .rew-expression-input-select .vs__dropdown-menu
  .rew-group--false > .rew-group__body > div > .rew-group__rule.rew-rule {
    .rew-expression-select {
      .vs__dropdown-toggle {
        background: #ff990054 !important;

        .vs__selected {
          color: #f90;
        }
      }
    }
  }
}

.card-body::-webkit-scrollbar {
  //overflow: hidden;
  //&::-webkit-scrollbar {
    height: 7px;
    width: 3px;
    background-color: #f0f0f0;
  //}
}

.tabs-component-panels {
  //background-color: #00b83a;
}

// SVG WITH STROKE
.rules-expand-btn {
  svg {
    stroke: #636f83;
  }

  &:hover {
    svg {
      stroke: #FFFFFF;
    }
  }
}

.rules-engine {
  input {
    background-color: transparent;

    &::placeholder {
      color: #282f37 !important;
      opacity: 0.5;
    }
  }

  .vs__dropdown-menu {
    min-width: 280px !important;
  }
}
</style>
